﻿.o-banner {
    position: relative;
    height: 260px;
    background-position: 100% 0;
    background-size: cover;
    background-repeat: no-repeat;
    @include responsive(screen-phone) {
        height: 200px;
        overflow: hidden;
    }
    img {
        display: none;
    }
    h2 {
        background: url(/includes/img/bg-curves-purple.png) 0 0 no-repeat;
        background-size: 214px;
        width: 334px;
        height: 150px;
        position: absolute;
        font-size: 21px; 
        padding: 24px 0 0 24px;
        margin-top: 0;
        color: white;
        @include responsive(screen-desktop) {
            background-size: 334px;
            font-size: 30px;
            padding: 36px 0 0 36px;
            top: -16px;
            left: -16px;
        }

    }
    &__promotion {
        background: rgba(26, 23, 27, .65);
        height: 48px;
        color: white;
        font-size: 14px;
        font-weight: 700;
        line-height: 48px;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 0;
        @include responsive(screen-phone) {
            font-size: 13px;
            line-height: 24px;
            padding: 10px;
            height: auto;
        }
    }
    a {
        background: $brand-primary;
        color: white;
        height: 38px;
        line-height: 40px;
        margin-top: 4px;
        margin-right: 4px;
        padding: 0 12px;
        display: block;
        float: right;
        text-decoration: none;
        @include responsive(screen-phone) {
            margin: 3px auto 5px auto;
            width: 155px;
            float: none;
        }
        &:hover {
            background: $brand-primary-shadow;
        }

    }
}
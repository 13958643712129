﻿/* Slider */
.slick-slider
{
    margin: 0 auto;
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-active {
    cursor: pointer;
}
.slick-slide img
{
    display: block;
    width: 100%;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.slider-nav {
    padding: 0 36px;
    margin-top: 22px;
    .slick-list {
        border-left: 1px solid #d0d0d0;
        border-right: 1px solid #d0d0d0;
        margin-right: 1px;
    }
    .slick-slide {
        cursor: pointer;
        border-left: 0;
        height: 100%;
        padding: 18px;
        position: relative;
        border: 1px solid #d0d0d0;
        border-left: 0;
        outline: none; 
        @include responsive(screen-tablet) {
            padding: 10px;
            min-height: 46px;
            max-height: 46px;
        }
        @include responsive(screen-desktop) {
            min-height: 67px;
            max-height: 67px;
        }
        @include responsive(screen-large) {
            min-height: 95px;
            max-height: 95px;
        }
        &:hover {
            background: #e2e2e2;
        }
    }
    .slick-prev {
        text-indent: -9999px;
        border: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: 0;
        outline: none;
        position: absolute;
        margin-left: -32px;
        width: 30px;
        &:before {
            content: "";
            position: absolute;
            font-family: "fontello";
            content: "\e803";
            color: #b1b2b2;
            font-size: 32px;
            left: 1px;
            height: 43px;
            top: 0;
            bottom: 0;
            margin: auto;
            text-indent: 0;
        }
        &:hover {
            &:before {
                color: #333;
            }
        }
    }
    .slick-next {
        text-indent: -9999px;
        border: 0;
        background: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin-left: -32px;
        margin: auto;
        outline: none;
        position: absolute;
        width: 30px;
         &:before {
            content: "";
            position: absolute;
            font-family: "fontello";
            content: "\e804";
            color: #b1b2b2;
            font-size: 32px;
            height: 43px;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            text-indent: 0;
        }
        &:hover {
            &:before {
                color: #333;
            }
        }
    }
}


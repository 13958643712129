﻿.c-product-gallery {
    padding: 50px;
    border: 1px solid #d0d0d0;
    @include responsive(screen-phone) {
        padding: 24px;
    }
    @include responsive(screen-tablet) {
        padding: 32px;
    }
    img {
        display: block;
        width: 100%;
    }
    h1 {
        color: $gray-darker;
        font-weight: 400;
        margin-top: 0;
        font-size: 21px;
        margin-bottom: 16px;
        margin-top: -6px;
    }
}
.c-product {
    font-size: 16px;
    margin-top: 12px;
    @include responsive(screen-desktop) {
        margin-top: 0;
    }
    h1 {
        font-size: 24px;
        color: $gray-darker;
        font-weight: 400;
        margin-bottom: 24px;
        margin-top: 0;
        @include responsive(screen-phone) {
            font-size: 21px;
            margin-bottom: 16px;
        }
    }
    .art-number {
        font-size: 16px;
        margin-bottom: 5px;
        display: block;
    }
    .price {
        font-size: 12px;
        display: block;
        float: left;
        b {
            font-weight: 700;
            font-size: 18px;
        }
    }
    .special-offer {
        background: #1a7dbd;
        display: block;
        color: white;
        font-size: 15px;
        height: 40px;
        width: 115px;
        line-height: 40px;
        text-align: center;
        margin: 12px 0;
    }
    .attention-item {
        font-style: italic;
    }
    .supply {
        font-size: 16px;
        margin-left: 12px;
        line-height: 28px;
        &.available {
            color: #62992f;
        }
    }
    p {
        clear: both;
        display: block;
        font-size: 16px;
        margin-top: 16px;
        @include responsive(screen-phone) {
            margin-top: 16px;
        }
        span {
            font-family: "Lato",Arial,Helvetica,sans-serif !important; 
            font-size: 16px !important;
        }
    }
    &__order {
        margin: 44px 0;
        @include responsive(screen-phone) {
            margin: 24px 0;
        }
        label {
            float: left;
            font-size: 16px;
            margin-right: 12px;
            line-height: 40px;  
        }
        input {
            height: 40px;
            line-height: 56px;
            padding: 0 0 0 5px;
            font-size: 16px;
            width: 70px;
            margin-right: 15px;
            float: left;
            border-radius: 0;
            @include responsive(screen-phone) {
                margin-bottom: 24px;
            }
        }
        .AddToCartContainer {
            position: relative;
        }
        .add-to-cart-button {
            background: $brand-primary;
            border: 0;
            height: 40px;
            width: 274px;
            line-height: 40px;
            position: relative;
            color: white;
            top: -25px;
            left: 0;
            font-size: 16px;
            padding-left: 32px;
            transition: all .3s;
            @include responsive(screen-phone) {
                display: block;
                clear: both;
                left: 0;
                top: -5px;
            }     
            &:hover {
                background: #771F6C;
            }
        }
         .cart-icon {
            position: relative;
            display: block;
            width: 25px;
            height: 25px;
            left: 150px;
            bottom: -4px;
            pointer-events: none;
            @include responsive(screen-phone) {
                left: 0;
                bottom: -63px;
            }
            &:before {
                position: absolute;
                font-family: "fontello";
                content: "\e801";
                color: white;
                font-size: 24px;
                left: 10px;
                margin: auto;
                width: 17px;
                height: 33px;
                z-index: 10;
            }
        }
    }
}
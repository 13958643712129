﻿.lightbox {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0; 
    top: 0;
    z-index: 2000;
    overflow-y: scroll; 
    -webkit-overflow-scrolling: touch; 
    @include responsive(screen-phone) {
        display: none !important;
    }
    .lightbox-bg {
        display: table;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 99;
        background: #000000;
        opacity: .5;
        overflow: hidden;
    }
    > div {
        display: table-cell;
        vertical-align: middle;
        position: fixed;
        z-index: 100;
        height: 100%;
        padding: 0 10px;
        @include responsive(screen-from-tablet) {
            position: relative;
            padding: 0;
        }
        @include responsive(screen-phone) {
            padding: 0;
        }
        .content {
            background: #ffffff;
            position: fixed; 
            width: 100%;
            margin: 0 auto;
            height: 100%;
            overflow-y: auto;
            @include responsive(screen-from-tablet) {
                width: 781px;
                max-height: 790px;
                position: relative;
                height: auto;
            } 
            img {
                width: auto;
                height: auto;
                display: none;
                position: relative;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 690px;
                padding: 40px;
                &:first-of-type {
                    display: block;
                }
            }
            .close {
                background: $brand-primary;
                transition: all .3s;
                cursor: pointer;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                position: absolute;
                right: 15px;
                top: 15px;
                color: white;
                font-size: 14px;
                text-align: center;
                font-weight: 700;
                padding-top: 5px;
                &:hover {
                    background: $brand-primary-shadow;
                }
            }
        }
    }
} 
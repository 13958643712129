﻿.product-listing {
    h2 {
        @include responsive(screen-from-tablet) {
            margin-left: -12px;
        }
    }
}

.c-products {
    margin-bottom: 44px;
    @include responsive(screen-phone) {
        margin-bottom: 0;
    }
    h3 {
        font-size: 20px;
        margin-top: 0;
        line-height: 100%;
        margin-top: 24px;
    }
    .o-product {
        text-decoration: none;
        color: $gray-darker;
        display: block;
        position: relative;
         @include responsive(screen-phone) {
            margin-bottom: 16px;
            border-bottom: 1px solid #d0d0d0;
            padding-bottom: 16px;
        }
         .special-offer {
            background: #1a7dbd;
            display: block;
            color: white;
            font-size: 15px;
            height: 40px;
            width: 115px;
            line-height: 40px;
            text-align: center;
            position: absolute;
            right: 0;
            top: 16px;
         }
        &:hover {
            img {
                background: whitesmoke;
            }
            p {
                color: black;
                transition: all .3s;
                text-decoration: underline;
            }
        }
        img {
            padding: 40px;
            border: 1px solid #d0d0d0;
            width: 100%;
            transition: all .3s;
        }
        p {
            font-size: 16px;
            font-weight: 700;
            @include responsive(screen-tablet) {
                height: 116px;
            }
            @include responsive(screen-desktop) {
                height: 84px;
            }
            @include responsive(screen-large) {
                height: 66px;
            }
        }
        .pricing {
            background: #f2f2f2;
            display: block;
            height: 38px;
            width: 100%;
            padding-left: 10px;
            color: $gray-darker;
            line-height: 38px;
            margin-top: 14px;
            transition: all .3s;
        }
    }
}

.c-productlist-search, .c-searchlist-search {
    width: 100%;
    position: relative;
    margin-bottom: 24px;
    .form-horizontal .form-group {
        @include responsive(screen-phone) {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
        }
    }
    input {
         width: 100%;
         height: 50px;
         border: 1px solid #c2c2c2;
         font-size: 16px;
         line-height: 50px;
         padding-left: 48px;
         outline: none;
         &::placeholder {
            color: #979797;
         }
         @include responsive(screen-phone) {
             float: left;
        }
    }
    .editing-form-label-cell {
        display: none;
    }
    .search-submit, input[type="submit"] {
        background: white;
        font-family: "fontello";
        font-size: 24px;
        content: "\e800";
        color: #912683;
        position: absolute;
        border: 0;
        left: -11px; 
        top: 1px;
        height: 48px;
        width: 48px;
        padding-left: 0;
        line-height: 45px;
        transition: all .3s;
        @include responsive(screen-phone) {
             left: 1px;
        }
    }
}

.c-product-listing {
    @include responsive(screen-from-tablet) {
        margin: 0 -12px;
    }
    .o-product {
        border: 1px solid #c2c2c2;
        //transition: all .3s;
        display: block;
        padding: 21px;
        text-decoration: none;
        margin-bottom: 24px;
        overflow: hidden;
        clear: both;
        @include responsive(screen-tablet) {
            padding: 16px;
        }
        &:hover {
            background: whitesmoke;
        }
        h3 {
            color: #912683;
            margin-top: 0;
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            height: 54px;
        }
        img {
            height: 90px;
            float: left;
        }
        .product-wrapper {
            position: relative;
            float: left;
            width: 100%;
        }
        .pricing-info {
            position: absolute;
            right: 0;
            bottom: 0;
            .special-offer {
                background: #1a7dbd;
                display: block;
                color: white;
                font-size: 15px;
                height: 40px;
                width: 115px;
                line-height: 40px;
                text-align: center;
                margin-right: -21px;
                margin-top: -2px;
            }
            .from-price {
                font-size: 14px;
                text-align: right;
                display: block;
                color: $gray-darker;
                text-decoration: line-through;
                font-weight: 700;
            }
            .pricing {
                font-weight: 700;
                display: block;
                font-size: 18px;
                margin-top: 10px;
                color: $gray-darker;
                text-align: right;
                &.for {
                    color: $brand-primary;
                }
            }
            .supply {
                color: #62992f;
                font-size: 12px;
                display: block;
                float: right;
                line-height: 100%;
                &.not-available {
                    color: $gray-darker;
                }
            }
        }   
        p {
            color: $gray-darker;
            font-size: 14px;
            line-height: 23px;
            margin-bottom: 0;
            float: left;
            width: 100%;
            margin-top: 30px;
            display: block;
            margin-bottom: 12px;
            overflow: hidden;
            @include responsive(screen-tablet) {
                margin-top: 16px;
            }
            @include responsive(screen-from-tablet) {
                height: 135px;
            }
        }
        .addtocart {
            background: $brand-primary;
            float: left;
            width: 100%;
            position: relative;
            height: 40px;
            line-height: 40px;
            color: white;
            display: block;
            border: 0;
            width: 100%;
            &:before {
                position: absolute;
                font-family: "fontello";
                content: "\e801";
                color: white;
                font-size: 24px;
                left: 10px;
                margin: auto;
                width: 17px;
                height: 33px;
                z-index: 10;
            }
            input[type="submit"] {
                border: 0;
                padding-left: 46px;
                text-align: left;
                width: 100%;
                height: 40px;
                background: transparent;
            }
        }
    }
    hr {
        float: left;
        margin-top: 0;
        width: 100%;
    }
}
.pagination, .pagination-list {
    display: block;
    margin: 24px auto;
    width: auto;
    list-style: none;
    text-align: center;
    @include responsive(screen-phone) {
        width: 100%;
    }
    .paging-summary {
        text-align: center;
        display: block;
        margin-top: 42px;
    }
    > li {
        display: inline;
    }
    > li > a, > li > span {
        border-radius: 5px;
        margin-right: 10px;
        font-size: 16px;
        font-weight: 700;
        border: 1px solid #ddd;
        width: 36px;
        height: 36px;
        display: block;
        float: left;
        text-align: center;
        padding-top: 6px;
        @include responsive(screen-phone) {
            margin-right: 5px;
            margin-bottom: 5px;
        }
        &.disabled {
            color: #afafaf;
            pointer-events: none;
        }
        &.page {
            border: 0;
        }
        &.nav {
            font-size: 21px; 
            padding-top: 0;
            padding-left: 2px;
           
        }
        &.active {
            background: #eee;
            border: 1px solid #ddd;
            pointer-events: none;
            color: $gray-darker;
        }
    }
    .sr-only {
        position: relative;
    }
    li:last-child a {
        margin-right: 0;
    }
}
.pagination-list {
    padding-left: 0;
    display: inline-block;
    width: auto;
    margin: 24px 0;
}
.pagination-pages {
    display: none; 
}
.pagination-items-per-page select {
    width: 274px;
    margin: 0 auto;
}
.paging-summary {
    display: none !important;
}
﻿.c-filters {
    @include responsive(screen-phone) {
        display: none;
    }
     @include responsive(screen-from-tablet) {
        display: block !important;
    }
    h4 {
        font-size: 20px;
        margin-top: 18px;
    }
    .checkbox-list-vertical {
        padding-left: 0px;
        label {
            font-size: 16px;
            margin-bottom: 20px;
        }
        /* Base for label styling */
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
          position: absolute;
          left: -9999px;
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
          position: relative;
          padding-left: 2.50em;
          cursor: pointer;
        }

        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
          content: '';
          position: absolute;
          left: 0; top: 0;
          width: 27px; 
          height: 27px;
          border: 1px solid #979797;
          background: #fff;
          border-radius: 4px;
        }
        [type="checkbox"]:checked + label:before {
            background: $brand-primary;
        }
        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
          content: '✔';
          position: absolute;
          top: 7px; 
          font-weight: bold;
          left: .3em;
          font-size: 1.1em;
          line-height: 0.8;
          color: #e9d7e7;
          transition: all .2s;
        }
        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
          transform: scale(0);
        }
        [type="checkbox"]:checked + label:after {
          opacity: 1;
          transform: scale(1);
        }
        /* disabled checkbox */
        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
          box-shadow: none;
          border-color: #bbb;
          background-color: #ddd;
        }
        [type="checkbox"]:disabled:checked + label:after {
          color: #999;
        }
        [type="checkbox"]:disabled + label {
          color: #aaa;
        }
        /* accessibility */
        [type="checkbox"]:checked:focus + label:before {
          background: $brand-primary;
        }
    }
}
.filter-toggle {
    cursor: pointer;
    border: 1px solid #c2c2c2;
    height: 50px;
    display: block;
    float: right; 
    width: 28%;
    margin-top: -7px;
    text-align: left;
    padding-left: 10px;
    font-weight: 700;
    line-height: 50px;
    position: relative;
    margin-bottom: 18px;
    &:hover {
        background: whitesmoke;
    }
    &:after {
        position: absolute;
        color: #565656;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 12px;
        content: "\e811";
        font-family: "fontello";
    }
    &.toggled {
        background: whitesmoke;
    }
}

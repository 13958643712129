﻿header {
    .container {    
        border-top: 7px solid #d3a7cd;
        margin-top: -7px;
        position: relative;
        padding-bottom: 18px;
        @include responsive(screen-tablet) {
            padding-bottom: 10px;
        }
    }
    hr {
        margin-top: 0;
        margin-bottom: 0;
        @include responsive(screen-phone) {
            display: none;
        }
    }
}

.o-logo {
    display: block;
    img {
        margin: -24px 0 36px 0;
        width: 60%;
        height: auto;
        float: left;
        @include responsive(screen-tablet) {
            margin: -36px 0 32px 0;
            width: 50%;
        }
        @include responsive(screen-phone) {
            width: 172px;
            margin: 10px 0 14px 0;
        }
        @include responsive(screen-desktop) {
            width: 53%;
        }
        @include responsive(screen-large) {
            width: 60%;
        }
    }
    span {
        float: left;
        display: block;
        font-size: 26px;
        color: $brand-primary;
        font-style: italic;
        margin-left: 10px;
        margin-top: 16px;
        @include responsive(screen-phone) {
            font-size: 21px;
            margin-left: 3px;
            margin-top: 18px;
        }
        @include responsive(screen-tablet) {
            margin-top: -28px;
            font-size: 20px;
        }
        @include responsive(screen-desktop) {
            margin-top: -23px;
            font-size: 24px;
        }
        @include responsive(screen-large) {
            margin-top: -13px;
        }
    }
}
.c-topnav {
    float: right;
    position: relative;
    margin-top: 7px;
    a {
        color: $brand-primary;
    }
    &__links {
        float: left;
        a {
            line-height: 45px;
            float: left;
            margin-right: 14px;
            text-decoration: underline;
            @include responsive(screen-phone) {
                float: none;
                line-height: 100%;
                margin-bottom: 21px;
                display: block;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
.c-topnav__search, .c-topnav__search-mobile {
    float: left;
    margin-top: 5px;
    position: relative;
    @include responsive(screen-phone) {
        margin: 22px 0;
        float: none;
        display: none;
        border-bottom: 1px solid #ddd;
        padding-bottom: 21px;
        margin-bottom: 0;
    }
    .search-input {
        border: 1px solid #c2c2c2;
        color: #979797;
        font-size: 16px;
        line-height: 34px;
        height: 34px;
        width: 207px;
        padding-left: 42px;
        outline: none;
        @include responsive(screen-phone) {
            width: 100%;
        }
    }
    .search-submit {
        background: white;
        font-family: "fontello";
        content: "\e800";
        color: $brand-primary;
        position: absolute;
        border: 0;
        left: 1px;
        top: 1px;
        height: 32px;
        width: 33px;
        transition: all .3s;
        &:hover {
            color: #222;
        }
    }
}
.c-navbar-mobile {
    background: $brand-primary;
    height: 40px;
    margin: 0 -12px;
    position: relative;
    overflow: hidden;
    &__expand-nav {
        background: $brand-primary;
        cursor: pointer;
        margin-left: 0;
        padding-left: 15px;
        width: 53px;
        height: 40px;
        &:hover {
          background: $brand-primary-shadow;
        }
        .hamburger-box {
            width: 21px;
            margin-top: 11px;
        }
        .hamburger-inner:before, .hamburger-inner:after, .hamburger-inner {
            background-color: white;
            width: 21px;
            height: 2px;
        }
        .hamburger-inner:before {
            top: 8px;
        }
        .hamburger-inner:after {
            top: 16px;
        }
        &.is-active {
            .hamburger-box {
                margin-top: 9px;
            }
            .hamburger-inner:before {
                top: 10px;
            }
            .hamburger-inner:after {
                top: 20px;
            }
        }
    }
    .o-button-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        width: 80px;
    }
    &__profile-link {
        float: left;
        width: 40px;
        height: 40px;
        position: relative;
        cursor: pointer;
        background: $brand-primary;
        &:after {
            content: "";
            position: absolute;
            font-family: "fontello";
            content: "\e802";
            color: white;
            font-size: 24px;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            width: 17px;
            height: 33px;
        }
        &:hover {
          background: $brand-primary-shadow;
        }
    }
    &__shopping-cart {
        float: left;
        width: 40px;
        height: 40px;
        position: relative;
        cursor: pointer;
        transition: all .3s;
        &:after {
            content: "";
            position: absolute;
            font-family: "fontello";
            content: "\e801";
            color: white;
            font-size: 24px;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 25px;
            height: 33px;
        }
        &:hover {
          background: $brand-primary-shadow;
        }
        .counter {
            background: $brand-blue;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            font-size: 11px;
            display: block;
            position: absolute;
            right: 0;
            top: 2px;
            color: white;
            font-weight: 700;
            z-index: 1;
            padding: 1px;
            text-align: center;
        }
    }
    &__expand-search {
        background: $brand-primary;
        top: 0;
        cursor: pointer;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        &:after {
            content: "";
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            font-family: "fontello";
            content: "\e800";
            color: white;
            font-size: 20px;
            width: 20px;
            height: 30px;
        }
        &:hover {
            background: $brand-primary-shadow;
        }
    }
}
.c-mainnav {
    margin-bottom: 54px;
    display: block;
    @include responsive(screen-phone) {
        display: none;
        width: 98%;
        background: white;
        box-shadow: 1px 1px 2px 0px rgba(124,124,124,0.37);
        overflow: hidden;
        margin: 0 0 0 -25px;
        padding: 32px 0 32px 25px;
        position: absolute;
        z-index: 1;
    }
    @include responsive(screen-tablet) {
        margin-bottom: 44px;
    }
    @include responsive(screen-from-tablet) {
        display: block !important;
    }
    .c-mainnav__links {
        a {
            color: $gray-darker;
            font-weight: 700;
            margin-right: 24px;
            display: block;
            float: left;
            font-size: 18px;
            padding-left: 18px;
            position: relative;
            text-decoration: none;
            line-height: 100%;
            @include responsive(screen-phone) {
                float: none;
                padding-left: 21px;
                margin-bottom: 30px;
            }
             @include responsive(screen-tablet) {
                margin-right: 21px;
            }
            @include responsive(screen-large) {
                font-size: 21px;
                margin-right: 44px;
            }
            &:last-child {
                margin-right: 0;
            }
            &:before {
                position: absolute;
                content: "";
                left: -6px;
                top: 2px;
                background: url(/includes/img/arrow-purple.png);
                width: 18px;
                height: 19px;
                @include responsive(screen-phone) {
                    left: -4px;
                    top: 0;
                }
                @include responsive(screen-from-tablet) {
                    top: 0;
                }
                @include responsive(screen-large) {
                    top: 2px;
                }
            }
            &:hover {
                color: $brand-primary;
            }
            &.hbo {
                &:before {
                    background: url(/includes/img/arrow-blue.png);
                }
                &:hover {
                    color: $brand-blue;
                }
            }
        }
    }
}
.c-shopping-cart {
    position: relative;
    margin-top: -36px;
    @include responsive(screen-tablet) {
        margin-top: -16px;
    }
    a {
        color: #2a2525;
        float: right;
        text-decoration: none;
        padding: 20px 10px 15px 10px;
        width: 100%;
        border-color: whitesmoke;
        border: 1px solid white;
        @include responsive(screen-tablet) {
            width: 64px;
            height: 50px;
            border: 1px solid #ddd;
        }
        &:hover {
            border: 1px solid #ddd;
        }
        b {
            font-size: 16px;
        }
        span {
            display: block;
        }
        @include responsive(screen-tablet) {
            b, span {
                display: none;
            }
            .counter {
                display: block;
                width: 21px;
                height: 21px;
                font-size: 14px;
                padding-top: 1px;
                padding-left: 6px;
                right: -67px;
            }
            .c-shopping-cart__cart {
                &:after {
                    font-size: 32px;
                    right: auto;
                    left: 21px;
                }
            }
        }
        @include responsive(screen-large) {
            width: 75%;
        }
    }
    &__cart {
        position: absolute;
        right: 72px;
        top: 20px;
        &:after {
            content: "";
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            font-family: "fontello";
            content: "\e801";
            color: #2a2525;
            font-size: 50px;
            width: 50px;
            height: 30px;
            transition: all .3s;
        }
        .counter {
            background: $brand-blue;
            transition: all .3s;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            color: white;
            position: absolute;
            right: -62px;
            top: -14px;
            z-index: 1;
            font-size: 16px;
            padding: 5px;
            text-align: center;
            &[data-count="0"] {
                background: gray;
            }
        }
    }
}

.breadcrumbs {
    margin: 0 0 24px 0;
    .CMSBreadCrumbsLink {
        text-decoration: underline;
    }
    .CMSBreadCrumbsCurrentItem {
        color: #686769;
    }
    .breadcrumb-seperator {
        margin: 0 5px;
    }
    .visible-lg {
        display: inline !important;
    }
}


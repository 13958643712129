﻿.c-checkout-steps {
    width: 425px;
    margin: 0 auto 32px auto;
    overflow: hidden;
    @include responsive(screen-phone) {
        width: 100%;
        margin-bottom: 16px;
    }
    h3 {
        text-align: center;
        font-size: 20px;
        @include no-select;
        font-weight: 500;
        @include responsive(screen-phone) {
            text-align: left;
            margin-top: 0;
        }
    }
    .step {
        width: 69px;
        height: 69px;
        background: #e5e5e5;
        display: block;
        float: left;
        border-radius: 50%;
        margin-right: 20px;
        position: relative;
        cursor: pointer;
        @include responsive(screen-phone) {
            width: 40px;
            height: 40px;
            margin-right: 15px;
        }
        &:last-child {
            margin: 0;
        }
        &:hover {
            background: #CBCBCB;
        }
        &.disabled {
            cursor: default;
            pointer-events: none;
            &:hover {
                background: #e5e5e5;
            }
        }
        &:after {
            content: "";
            font-family: "fontello";
            position: absolute;
            color: white;
            font-size: 36px;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;  
        }
        &.active {
            background: $brand-primary;
            &:hover {
                background: $brand-primary-shadow;
            }
        }
        &.step-1:after {
            left: 14px;
            content: "\e801";
            color: white;
            font-size: 36px;
            height: 47px;
            @include responsive(screen-phone) {
                font-size: 26px;
                left: 7px;
                height: 32px;
            }
        }
        &.step-2:after {
            left: 20px;
            content: "\e802";
            color: white;
            font-size: 40px;
            height: 58px;
            @include responsive(screen-phone) {
                font-size: 26px;
                left: 11px;
                height: 35px;
            }
        }
        &.step-3:after {
            left: 16px;
            content: "\e807";
            color: white;
            font-size: 34px;
            height: 50px;
            @include responsive(screen-phone) {
                font-size: 21px;
                left: 9px;
                height: 29px;
            }
        }
        &.step-4:after {
            left: 19px;
            content: "\e805";
            color: white;
            font-size: 34px;
            height: 46px;
            @include responsive(screen-phone) {
                font-size: 21px;
                left: 10px;
                height: 29px;
            }
        }
        &.step-5:after {
            left: 18px;
            content: "\e806";
            color: white;
            font-size: 36px;
            height: 52px;
            @include responsive(screen-phone) {
                font-size: 23px;
                left: 9px;
                height: 32px;
            }
        }
    }
}
.actions {
    position: absolute;
    bottom: 0;
    right: 0;
}
.cart-action-label {
    display: block;
    float: left;
    font-size: 16px;
    margin-top: 26px;
}
.cart-actions {
    display: block; 
    list-style: none;
    padding-left: 0;
    float: right;
    padding-top: 12px;
}
.c-checkout-list, .CartContent {
    border-top: 1px solid #ddd;
    > div {
        @include responsive(screen-phone) {
            padding: 0 15px;
        }
    }
    .FormErrorLabel {
        color: red;
    }
    .CartContent {
        border: 0;
    }
    .product {
        padding: 20px 0;
        border-bottom: 1px solid #ddd;
        overflow: hidden;
        position: relative;
        width: 100%;
        img {
            float: left;
            width: 100px;
            margin-right: 28px;
        }
        h4 {
            margin-top: 0;
            font-size: 16px;
            float: left;
            width: 60%;
            @include responsive(screen-desktop) {
                width: 40%;
            }
            @include responsive(screen-phone) {
               width: 100%;
               margin-top: 16px;
            }
        }
        .pricing-info {
            position: absolute;
            right: 0;
            top: 20px;
            span {
                float: right;
                clear: both;
            }
            .from-price {
                text-decoration: line-through;
                font-size: 14px;
                margin-right: 6px;
                font-weight: 700;
                float: left;
                margin-top: 4px;
                display: block;
            }
            .price {
                font-size: 18px;
                color: $brand-primary;
                font-weight: 700;
                float: right;
            }
            .btw {
                color: #555;
                font-size: 12px;
                display: block;
                float: right;
                clear: both;
            }
            .supply {
                color: #62992f;
                font-size: 14px;
                float: right;
                clear: both;
                &.not-availble {
                    color: #555;
                }
            }
        }
        .update-order {
            float: left;
            width: 62%;
            @include responsive(screen-desktop) {
                margin-top: 6px;
            }
            @include responsive(screen-phone) {
                width: 100%;
            }
            span {
                font-size: 16px;
                float: left;
                margin-right: 12px;
                margin-bottom: 24px;
                line-height: 38px;
                + div, + div div {
                    float: left;
                }
            }
            input {
                height: 38px;
                width: 50px;
                line-height: 38px;
                border: 1px solid #ddd;
                font-size: 16px;
                padding-left: 18px;
                margin-right: 16px;
            }
            a, .UnitCountButton input, .RemoveButton  {
                background: none;
                border: 0;
                text-decoration: underline;
                font-size: 16px;
                margin-right: 16px;
                width: auto;
                float: left;
                padding-left: 0;
                color: $brand-primary;
                @include responsive(screen-phone) {
                   display: block;
                   margin-top: 6px;
                }
            }
        }
    }
    .ShowAddressCheckbox .checkbox input[type="checkbox"] {
        margin-left: -20px;
    }
    .Persoonsgegevens, .billing {
        font-size: 18px;
        font-weight: 700;
        display: block;
        margin: 24px 0 24px -12px;
        @include responsive(screen-tablet) {
            margin:24px 0 -24px -12px;
        }
        @include responsive(screen-phone) {
            margin: 24px 0;
        }
    }
    .radio {
        input[type="radio"] {
            margin-left: 0;
        }
        label {
            margin-right: 20px;  
        }
    }
    .checkbox label {
        padding-left: 10px;
    }
    .control-label.editing-form-label {
        font-size: 16px;
        line-height: 30px;
        font-weight: 700;
        @include responsive(screen-tablet) {
            margin-bottom: 8px;
            margin-top: 24px;
            display: block;
            text-align: left;
        }
    }
    #field_CustomerFirstName .editing-form-label-cell label {
        @include responsive(screen-tablet) {
            margin-top: 0;
        }
    }
   #field_AccountType .editing-form-label-cell span {
       text-align: left;
       @include responsive(screen-tablet) {
        margin: 24px 0 12px 0;
       }
   }
   .editing-form-label-cell {
       float: left;
       width: 23%;
        @include responsive(screen-phone) {
            width: 100%;
       }
       @include responsive(screen-desktop) {
            width: 30%;
        }
        @include responsive(screen-tablet) {
            width: 100%;
        }
   }
   .form-control-error {
       color: red;
   }
   .editing-form-value-cell {
       float: left;
       width: 75%;
       @include responsive(screen-desktop) {
            width: 70%;
        }
        @include responsive(screen-tablet) {
            width: 100%;
        }
        @include responsive(screen-phone) {
            width: 100%;
        }
   }
   .ShowAddressCheckbox {
       padding-left: 10px;
       margin-bottom: 32px;
   }
   .form-control-text {
       float: left;
       line-height: 40px;
       margin-right: 8px;
   }
   .input-width-20 {
       float: left;
       width: 50px;
   }
   .input-width-60 {
       float: left;
       width: 480px;
       margin-left: 22px;
       @include responsive(screen-desktop) {
           width: 325px;
        }
        @include responsive(screen-phone) {
           width: 66%;
        }
   }
}
.c-total-price {
    background: #f3f3f3;
    clear: both;
    margin: 20px 0;
    padding: 20px;
    overflow: hidden;
    > div {
        float: right;
        width: 350px;

        @include responsive(screen-phone) {
          width: 100%;
        }

       .label {
           float: left;
           clear: both;
           margin-bottom: 16px;
           width: calc(100% - 75px);
        }
        span:not([class]) {
            float: right;
            width: 75px;
            text-align: right;
        }
        .subtotal {
            clear: both;
            float: left;
            width: calc(100% - 75px);
            font-size: 16px;
            font-weight: 700;
            margin-top: 16px;
        }
        .total-price .Value span {
            font-size: 18px;
            font-weight: 700;
            margin-top: 16px;
        }
        .Value, .TotalViewer {
            width: auto;
            .Label {
                display: none;
            }
            span {
                float: right;
                font-weight: 500;
                font-size: 16px;
                margin-top: 0;
            }
        }
    }
}

.DiscountSummary .TotalViewer div.Label { 
    display: inherit !important; 
    float: left;
    clear: both;
    margin-bottom: 16px;
    width: calc(100% - 75px);    
}

.DiscountSummary .TotalViewer div.Label span {
   font-size: 14px !important;
    width: 75%;
    text-align: left;
    float: left;
}

.o-back-link {
    font-size: 16px;
    float: left;
    margin-top: 12px;
    @include responsive(screen-phone) {
        margin-top: 0;
    }
}

.c-personal-info {
    h4 {
        font-size: 21px;
        margin-bottom: 16px;
    }
    .info-row {
        clear: both;
        label {
            float: left;
            display: block;
            width: 100px;
        }
        span {
            display: block;
            float: left;
        }
    }
}

.c-confirmation {
    margin-bottom: 64px;
    h4 {
        font-size: 21px;
        margin-bottom: 16px;
    }
    .order_id {
        font-size: 16px;
    }
    p {
        font-size: 16px;
    }
    .btn-primary {
        background: #912683;
        color: white;
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        font-size: 16px;
        float: right;
        text-decoration: none;
        margin-top: 16px;
        margin-bottom: 32px;
        cursor: pointer;
        border: 0;
    }
}

.checkout-container {
    @include responsive(screen-phone) {
        overflow: hidden;
    }
    .delivery-label {
        float: left;
        width: 100%;
        font-size: 16px;
        margin-top: 24px;
    }
    .PanelPayment {
        float: left;
        width: 320px;
        @include responsive(screen-phone) {
            width: 100%;
        }
    }
    .checkout-button {
        background:center $brand-primary;
        border: 0;
        color: white;
        height: 40px;
        padding: 0 24px;
        margin-left: 16px;
        float: right;
        margin-bottom: 32px;
        @include responsive(screen-phone) {
            margin-left: 0;
            margin-top: 12px;
            float: right;
        }
    }
    .personal-info {
        margin-bottom: 32px;
        overflow: hidden;
        div {
            font-size: 16px;
        }
    }
    .checkbox input[type="checkbox"] {
        margin-left: 0;
    }
    .ErrorLabel {
        color: red;
        display: block;
        margin: 16px 0;
    }
    .o-back-link {
        @include responsive(screen-from-tablet) {
            margin-bottom: 64px;
        }
    }
}
.ShoppingCartEmpty {
    display: block;
    margin-top: 16px;
}

.notes {
    span {
        display: block;
        margin-bottom: 10px;
    }
}

.discount {
    margin-top: 24px;
    .form-control {
        float: left;
        border-right: 0;
        width: 80%;
        @include responsive(screen-phone) {
            border-right: 1px solid #ccc;
            width: 100%;
            margin-bottom: 12px;
        }
    }
    span {
        display: block;
        margin-bottom: 16px;
    }
    input[type="submit"] {
        border: 0;
        background: #912683;
        color: white;
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        font-size: 16px;
        float: left;
        text-decoration: none;
        margin-bottom: 16px;
        cursor: pointer;
        width: 20%;
        @include responsive(screen-phone) {
            margin-bottom: 0;
            width: 100%;
        }
    }
}
.expand-order {
    span {
        font-size: 16px;
        margin: -8px 0 32px 0;
        display: block;
        text-align: right;
        width: 100%;
        color: $brand-green;
        font-weight: 700;
    }
}

.login-form {
    position: relative;
    margin-bottom: 96px;
    @include responsive(screen-tablet) {
        width: 64%;
    }
    @include responsive(screen-desktop) {
        width: 77%;
    }
    @include responsive(screen-large) {
        width: 59%;
    }
    .form-group {
        padding-left: 46px;
        @include responsive(screen-phone) {
            padding-left: 20px;
        }
        .editing-form-label-cell {
            float: left;
            font-size: 16px;
            width: 150px;
        }
        .editing-form-value-cell {
            float: left; 
            width: 252px;
        }
    }
    .logon-password-retrieval-link {
        bottom: 26px;
        position: absolute;
        right: 0;
        font-size: 12px;
        text-decoration: underline;
        @include responsive(screen-phone) {
            right: 20px;
        }
    }
    .logon-remember-me-checkbox.checkbox {
        padding-left: 52px;
        padding-top: 12px;
        label {
            padding-left: 8px;
        }
        input[type="checkbox"] {
            margin-left: -20px;
        }
    }
    .btn-primary {
        background: #912683;
        color: white;
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        font-size: 16px;
        float: left;
        text-decoration: none;
        margin-top: 16px;
        cursor: pointer;
        border: 0;
    }
}

.register-form {
    display: none;
    .CustomRegistrationForm {
        tr {
            display: block;
            margin-bottom: 24px;
            .password-strength {
                @include responsive(screen-phone) {
                    margin-top: -103px;
                }
            }
            .passw-strength-indicator {
                 @include responsive(screen-phone) {
                     margin-top: 36px;
                }
            }
            .password-strength-text {
               display: none;
            }
            .password-labels {
                vertical-align: top; 
                padding-top: 6px;
            }
            .confirm-password {
                margin-top: 35px;
                @include responsive(screen-phone) {
                    margin-top: 60px;
                }                
            }
            td:first-child {
                width: 150px;
            }
            &:nth-child(5) td div span {
                @include responsive(screen-phone) {
                    padding-top: 30px;
                    position: relative;
                    display: block;
                    margin-bottom: 0;
                }
            }
            td, td:first-child {
                @include responsive(screen-phone) {
                    width: 100%;
                    float: left;
                    margin-bottom: 12px;
                }        
            }
            &:last-child {
                margin-top: -15px;
                td span {
                    margin-top: 6px;
                    display: block;
                }
            }
        }
        span {
            font-weight: 700;
            margin-bottom: 24px;
            width: 150px;
        }
    }
    .radio {
        tr td, tr .td:first-child {
            float: left;
            width: 100px !important;
        }
        input[type="radio"] {
            margin-left: 0;
        }
    }
    .RegisterButton {
        background: #912683;
        color: white;
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        font-size: 16px;
        float: left;
        text-decoration: none;
        margin-bottom: 46px;
        cursor: pointer;
        border: 0;
        @include responsive(screen-phone) {
            margin-right: 0;
        }
    }
}
.cart-content + .row, .cart-content + .row + .row { padding: 0 12px; overflow: hidden;}
﻿.c-search-listing {
    .item {
       display: block;
       overflow: hidden;
       padding: 20px;
       border: 1px solid #ccc;
       margin-bottom: 24px;
       @include responsive(screen-phone) {
           padding: 12px;
           margin-bottom: 12px;
        }
       .img {
           float: left;
           @include responsive(screen-phone) {
               img {
                    max-width: 40px;
               }
            }
       }
       &:hover {
           background: whitesmoke;
       }
       h3 {
           margin-top: 0;
           float: left;
           margin-left: 0;
           font-size: 18px;
           @include responsive(screen-phone) {
                margin-bottom: 0;
           }
       }
       p {
            color: black;
            clear: both;
       }
       .descr {
           float: left;
           width: 80%;
           margin-left: 16px;
           @include responsive(screen-phone) {
                width: 70%;
           }
       }
    }
    .pagination {
        width: 316px;
        .paging-summary {
            text-align: center;
            display: block;
            margin-top: 42px;
        }
    }
}
.c-searchlist-search {
    margin-bottom: 0;
    .form-horizontal .form-group {
        margin: 0 0 16px 0;
        .control-label {
            display: none;
        }
        input {
            text-overflow: ellipsis;
        }
    }
    @include responsive(screen-phone) {
        width: 100%;
        input {
            float: left;
            width: 100%;
            margin-bottom: 0;
        }
    }
}
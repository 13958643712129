﻿.teaser {
    background: #f3f3f3;
    padding: 20px;
    margin-bottom: 32px;
    img {
        width: 100%;
        @include responsive(screen-phone) {
            width: 70%;
        }
        @include responsive(screen-tablet) {
            width: 60%;
        }
    }
    h3 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 18px;
    }
    .btn-primary {
        font-size: 16px;
        display: block;
    }
    .phone, .email {
        display: block;
        margin-top: 16px;
        line-height: 24px;
        font-size: 16px;
        padding-left: 40px;
        position: relative;
        text-decoration: underline;
        &:before {
            content: "\e809";
            font-family: "fontello";
            position: absolute;
            color: #222;
            font-size: 22px;
            margin: auto;
            top: 2px;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
    .email:before {
        content: "\e810";
        top: 0;
    }
}
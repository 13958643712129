﻿.btn-catalog {
    background: $brand-primary;
    height: 60px;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: 700;
    line-height: 60px;
    display: block;
    &:hover {
        color: white;
        text-decoration: none;
        background: $brand-primary-shadow;
    }
}

.cta-block {
    margin: 24px 0 84px 0;
    @include responsive(screen-phone) {
        margin-top: 0;
    }
    span {
        font-size: 16px;
        display: block;
        text-align: center;
        margin: 16px 0;
        a {
            text-decoration: underline;
            &:hover {
                color: $brand-primary-shadow;
            }
        }
    }
}

.continue, .checkout-button {
    background: $brand-primary;
    color: white;
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    font-size: 16px;
    float: right;
    text-decoration: none;
    margin-bottom: 46px;
    cursor: pointer;
    @include responsive(screen-phone) {
        margin-top: 24px;
        width: 100%;
        text-align: center;
    }
    &:hover {
        background: $brand-primary-shadow;
        color: white;
        text-decoration: none;
    }
}
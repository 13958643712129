﻿.my-account {
    .TabsHeader {
        border-bottom: 1px solid #ccc;
        margin-bottom: 24px;
    }
    .TabControlRow {
        width: 100%;
        overflow: hidden;
        @include responsive(screen-phone) {
            display: block;
        }
    }
    @include responsive(screen-phone) {
        .TabControlTable {
            display: block;
            tbody {
                display: block;
            }
        }
    }
    .ErrorLabel  {
        color: red;
        display: block;
        margin: 16px 0;
    }
    .EditingFormErrorLabel, .form-control-error {
        color: red;
        display: block;
    }

    .TabControl, .TabControlSelected {
        float: left;
        padding: 15px;
        font-weight: 700;
        margin-left: 3px;
        margin-bottom: -1px;
        font-size: 16px;
        @include responsive(screen-phone) {
            width: 100%;
            padding-left: 0;
            margin-left: 0;
            margin-bottom: -16px;
        }
    }
    .TabControlSelected {
        border: 1px solid #ccc;
        border-bottom: 1px solid white;
        @include responsive(screen-phone) {
            border: 1px solid #ccc;
            padding-left: 15px;
            background: whitesmoke;
        }
    }
    .editing-form-label-cell {
        font-size: 16px;
        float: left;
        width: 224px;
        font-weight: 700;
        @include responsive(screen-phone) {
            float: none;
        }
    }
    .LabelField {
        font-size: 16px;
        float: left;
        font-weight: 700;
    }
    .editing-form-value-cell {
        float: left;
        width: 300px; 
        @include responsive(screen-phone) {
            float: none;
            width: 100%;
        }
    }
    .radio input[type="radio"] {
        margin-left: 0;
    }
    .radio input[type="radio"] + label {
        margin-right: 8px;
    }
    .control-group-inline {
        position: relative;
    }
    .btn.icon-only {
        border: 0;
        border-left: 1px solid #ddd;
        height: 38px;
        width: 39px;
        position: absolute;
        top: 1px;
        right: 1px;
        &:before {
            position: absolute;
            font-family: "fontello";
            content: "\e808";
            color: #333;
            font-size: 21px;
            left: 9px;
            margin: auto;
            top: 4px;
            width: 17px;
            height: 33px;
            z-index: 10;
        }
    }
    .form-group-submit .btn.btn-primary {
        background: $brand-primary;
        border: 0;
        color: white;
        padding: 8px 15px;
        width: 170px;
        float: right;
        line-height: 25px;
        @include responsive(screen-desktop) {
            margin-right: 38px;
        }
        @include responsive(screen-large) {
            margin-right: 205px;
        }
        @include responsive(screen-tablet) {
            margin-right: 155px;
        }
        @include responsive(screen-phone) {
            margin-right: 0;
        }
    }
    .btn.btn-primary {
        background: $brand-primary;
        border: 0;
        color: white;
        padding: 8px 15px;
        width: 170px;
        line-height: 25px;
    }
    .form-horizontal {
        padding: 0 15px;
        @include responsive(screen-desktop) {
            min-height: 600px;
        }
        @include responsive(screen-tablet) {
            min-height: 600px;
        }
    }
    &.logon {
        .form-horizontal {
            position: relative;
            @include responsive(screen-desktop) {
                min-height: auto;
            }
            @include responsive(screen-tablet) {
                min-height: auto;
            }
        }
        .logon-remember-me-checkbox {
            margin-left: 25px;
            @include responsive(screen-phone) {
                margin-top: 20px;
            }
            label {
                padding-left: 12px;
            }
        }
        .error-label, .form-control-error {
            color: red;
            margin-bottom: 24px;
            display: block;
        }
        .btn.btn-primary, .logon-password-retrieval-button {
            background:center $brand-primary;
            border: 0;
            color: white;
            height: 40px;
            padding: 0 24px;
            margin-left: 16px;
            float: left;
            margin-bottom: 32px;
            margin-left: 427px;
            @include responsive(screen-phone) {
                float: right;
                margin-left: 0;
            }
        } 
        .logon-password-retrieval-button {
            margin-left: 417px;
        }
        .logon-password-retrieval-link {
            float: left;
            width: 30%;
            margin-top: -63px;
            @include responsive(screen-phone) {
                width: 76%;
                float: left;
            }
        }  
        .logon-panel-password-retrieval {
            margin-top: 32px;
            float: left;
            width: 100%;
        }
        .logon-password-retrieval-result {
            display: block; 
            float: left; 
            width: 100%;
            color: red;
            margin-bottom: 24px;
        }
    }
    &.MyOrders {
        min-height: 420px;
        thead {
           @include responsive(screen-phone) {
                float: left;
                width: 50%;
           }
        }
        .table {
            @include responsive(screen-phone) {
                thead {
                    display: none;
                }
                tbody {
                    float: left;
                    width: 100%;
                    td {
                        display: block;
                         &:nth-child(2) {
                            padding-left: 20px;
                            &:before {
                                content: "ID:";
                                position: absolute;
                                left: 12px;  
                            }
                        }
                    }
                    tr {
                        margin-bottom: 24px;
                        display: block;
                        padding: 12px 0;
                        border-bottom: 1px solid #ccc;     
                    }
                }
            }
        }
        table {
            width: 100%;
        }
        th {
            text-align: left;
            @include responsive(screen-phone) {
                display: block;
            }
            &:nth-child(7) {
                @include responsive(screen-phone) {
                    display: none;
                }
            }
        }
        .pagination {
            margin: 32px auto 0 auto;  
        }
        .pagination-items-per-page {
            margin: 0 0 32px 0;
            @include responsive(screen-phone) {
                max-width: 280px;
            }
        }
    }
}

.datetime-ui-datepicker select.datetime-ui-datepicker-month, .datetime-ui-datepicker select.datetime-ui-datepicker-year {
    float: left;
}
.datetime-ui-time-input, .datetime-ui-datepicker .datetime-ui-datepicker-prev, .datetime-ui-datepicker .datetime-ui-datepicker-next, #field_UserNickName, #field_UserSignature, #field_UserMessagingNotificationEmail, #field_UserTimeZoneID, #field_UserAvatarID, .calendar-action, .action-buttons button:nth-child(2) {
    display: none;
}
.password-strength-hint {
    margin-top: 8px;
    display: block;
}

.MyAddresses, .MyOrders  {
    .unigrid-actions-header-empty + th { 
        float: left;
        text-align: left;
        width: 300px;
        @include responsive(screen-phone) {
            display: none;
        }
    }
    .unigrid-actions-header-empty + th + th {
         text-align: left;
     }
    .unigrid-actions + td {
        @include responsive(screen-phone) {
            display: none;
        }
        a {
            margin-right: 20px;
        }
     }
    .pagination {
        margin-top: 24px;
    }
    .HiddenButton {
        display: none;
    }
}
.MyOrders {
    table {
        float: left;
        width: 100%;
        margin-bottom: 24px;
    }
    .unigrid-actions-header-empty + th { 
        width: auto;
    }
    th {  
        text-align: left;
        margin-right: 20px; 
    }
    td {
        margin-right: 20px;
        &:nth-child(5), &:nth-child(7) {
            @include responsive(screen-phone) {
                display: none;
            }
        }
    }
    .unigrid-head th:nth-child(5),   .unigrid-head th:nth-child(6),  .unigrid-head th:nth-child(7), .unigrid-head th:nth-child(8)  {
        display: none;
    }
}

﻿footer {
    border-top: 7px solid #912383;
    background: #f1f1f1;
    .container {
        background: #f1f1f1;
        border-top: 7px solid #d3a7cd;
        margin-top: -7px;
        padding-bottom: 44px;
    }
    h5 {
        font-size: 15px;
        margin-top: 40px;
        @include responsive(screen-phone) {
            margin-top: 24px;
        }
    }
    ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            position: relative;
            padding-left: 24px;
            margin-bottom: 5px;
            a {
                transition: all .3s;
                color: #1a171b;
                font-size: 15px;
            }
            &:before {
                display: inline-block;
                content: '';
                background: #912683;
                width: .3333em;
                height: .3333em;
                margin-right: .75em;
                position: absolute;
                top: .575em;
                left: 0;
            }
        }
    }
    h6 {
        font-weight: 100;
        padding-left: 24px;
        position: relative;
        font-style: italic;
        font-size: 16px;
        @include responsive(screen-phone) {
            margin-bottom: 0;
        }
        &:before {
            position: absolute;
            content: "";
            left: -4px;
            top: 2px;
            background: url(/includes/img/arrow-purple-2.png);
            width: 18px;
            height: 19px;
        }
    }
}
.c-copyright {
    background: #c9c9c9;
    height: 52px;
    .container {
        background: #c9c9c9;
    }
    span {
        line-height: 52px;
        color: #1a171b;
        font-size: 16px;
    }
}
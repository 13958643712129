﻿body {
    border-top: 7px solid #912383;
    font-family: "Lato",Arial,Helvetica,sans-serif;
    font-size: 14px;
    background: transparent;
    @include responsive(screen-phone) {
        border: 0;
        overflow: visible !important;
    }
}
html {
    background: #f1f1f1 url(/includes/img/bg-dotted-large.png) top center no-repeat;
}
a {
    transition: all .3s;
}
.container {
    background: white;
    @include responsive(screen-desktop) {
        padding: 0 54px;
        overflow: hidden;
    }
    @include responsive(screen-tablet) {
        padding: 0 32px;
    }
}
.center {
    margin: 0 auto;
    float: none;
}
hr {
    padding: 0;
    margin-top: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #ddd;
    border-top: 0;
}

h1 {
    font-size: 24px;
    font-weight: 500;
}

.form-control {
    border-radius: 0;
    height: 40px;
    line-height: 40px;
}

.content p {
    font-size: 16px;
}

.EditingFormValueCell input {
    width: 500px;
    float: right;
    margin-bottom: 24px;
    @include responsive(screen-phone) {
        width: 100%;
        float: left;
    }
}
.FieldLabel {
    width: 200px;
    float: left;
    line-height: 40px;
    font-size: 16px;
    @include responsive(screen-phone) {
        margin-right: 12px;
        width: 90%;
    }
}
.FormButton {
    float: right;
    margin-top: 24px;
    color: white;
    background: $brand-primary;
    border: 0;
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
    margin-bottom: 64px;
}

.custom-checkbox {
    margin: 24px 0;
    font-size: 16px;
    padding-left: 32px;
    position: relative;
    cursor: pointer;
    span {
        padding-left: 16px;
        font-size: 14px;
        display: block;
        line-height: 18px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0; 
      top: 0;
      width: 18px; 
      height: 18px;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 50%;
    }
    &.toggled:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 5px;
        left: 5px;
        background: $brand-primary;
        transition: all .2s;
    }
}
ul li {
    font-size: 16px;
}
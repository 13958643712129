﻿@font-face {
  font-family: 'fontello';
  src: url('/includes/font/fontello.eot?45463418');
  src: url('/includes/font/fontello.eot?45463418#iefix') format('embedded-opentype'),
       url('/includes/font/fontello.woff?45463418') format('woff'),
       url('/includes/font/fontello.ttf?45463418') format('truetype'),
       url('/includes/font/fontello.svg?45463418#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin no-select {
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
}